import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const CaretDown = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.6 7.2 9 10.8 5.4 7.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

export default CaretDown
