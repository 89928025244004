import STRINGS from './strings'

type External = { external?: boolean }

type TitleUrl = Record<'title' | 'url', string> & External

interface NavItem extends External {
  title: string
  url?: string
  children?: TitleUrl[]
}

const solution = {
  title: STRINGS.NAV.SOLUTION,
  children: [
    { title: STRINGS.NAV.SOLUTION_FOR_FARMER, url: '/solution-for-farmers' },
    {
      title: STRINGS.NAV.SOLUTION_FOR_MICRO_ENTREPRENEUR,
      url: '/solution-for-micro-entrepreneur',
    },
    {
      title: STRINGS.NAV.SOLUTION_FOR_INSTITUTIONAL_BUYERS,
      url: '/solution-for-institutional-buyers',
    },
  ],
}

const brands = {
  title: STRINGS.NAV.OUR_BRANDS,
  children: [
    {
      title: STRINGS.NAV.HONEST_FARMS,
      url: 'https://honestfarms.agrevolution.in/',
      external: true,
    },
    {
      title: STRINGS.NAV.FARMPLUS,
      url: 'https://farmplus.agrevolution.in/',
      external: true,
    },
  ],
}

const blogs = {
  title: STRINGS.NAV.BLOGS,
  children: [
    { title: STRINGS.NAV.FARMBOOK, url: '/farmbook' },
    {
      title: STRINGS.NAV.ENGINEERING_BLOG,
      url: 'https://write.agrevolution.in/tagged/engineering',
      external: true,
    },
  ],
}

const aboutUsMobile = {
  title: STRINGS.NAV.ABOUT_US,
  children: [
    { title: STRINGS.NAV.COMPANY, url: '/company' },
    { title: STRINGS.NAV.DEHAAT_NETWORK, url: '/business-model' },
    { title: STRINGS.NAV.CONTACT_US, url: '/contact-us' },
  ],
}

export const mobileNavLinks: NavItem[] = [
  solution,
  brands,
  blogs,
  aboutUsMobile,
  { title: STRINGS.NAV.KNOW_YOUR_SOIL, url: '/know-your-soil' },
  { title: STRINGS.NAV.AGRI_INPUT, url: '/agri-input' },
  {
    title: STRINGS.NAV.HEALTH_AND_GROWTH,
    url: '/health-growth-yield-prediction',
  },
  {
    title: STRINGS.NAV.AGRI_OUTPUT,
    url: '/agri-output-Harvest-and-Market-Access',
  },
  { title: STRINGS.NAV.FINANCE, url: '/Agricultural-Finance-Credit-Insurance' },
  {
    title: STRINGS.NAV.CAREERS,
    url: '/careers',
  },
]

export const desktopNavLinks: NavItem[] = [
  { title: STRINGS.NAV.COMPANY, url: '/company' },
  solution,
  { title: STRINGS.NAV.DEHAAT_NETWORK, url: '/business-model' },
  brands,
  blogs,
  { title: STRINGS.NAV.CAREERS, url: '/careers' },
  { title: STRINGS.NAV.CONTACT_US, url: '/contact-us' },
]
