import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const Caret = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" ref={ref} {...props}>
    <g clipPath="url(#a)">
      <path d="m18 12.75c6e-4 0.0987-0.0184 0.1965-0.0557 0.2879s-0.0923 0.1745-0.1618 0.2446c-0.0697 0.0703-0.1527 0.126-0.2441 0.1641s-0.1894 0.0577-0.2884 0.0577-0.197-0.0196-0.2884-0.0577-0.1744-0.0938-0.2441-0.1641l-6.1275-6.1275c-0.4219-0.42136-0.99375-0.65802-1.59-0.65802-0.59626 0-1.1681 0.23666-1.59 0.65802l-6.1275 6.1275c-0.14123 0.1412-0.33278 0.2205-0.5325 0.2205s-0.39127-0.0793-0.5325-0.2205c-0.14123-0.1413-0.22057-0.3328-0.22057-0.5325 0-0.1998 0.079341-0.3913 0.22057-0.5325l6.1275-6.1275c0.34834-0.34923 0.76216-0.6263 1.2177-0.81535 0.45559-0.18905 0.944-0.28636 1.4373-0.28636 0.49325 0 0.98166 0.09731 1.4372 0.28636 0.4556 0.18905 0.8695 0.46612 1.2178 0.81535l6.1275 6.1275c0.0695 0.07 0.1245 0.1531 0.1618 0.2445s0.0563 0.1893 0.0557 0.288z" />
    </g>
    <defs>
      <clipPath id="a">
        <rect transform="translate(0 18) rotate(-90)" width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
))

export default Caret
