import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const ThreeBar = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg
    width="512px"
    height="512px"
    enableBackground="new 0 0 56 56"
    viewBox="0 0 56 56"
    ref={ref}
    {...props}
  >
    <path d="M28,0C12.561,0,0,12.561,0,28s12.561,28,28,28s28-12.561,28-28S43.439,0,28,0z M28,54C13.663,54,2,42.336,2,28   S13.663,2,28,2s26,11.664,26,26S42.337,54,28,54z" />
    <path d="m40 16h-24c-0.553 0-1 0.448-1 1s0.447 1 1 1h24c0.553 0 1-0.448 1-1s-0.447-1-1-1z" />
    <path d="m40 27h-24c-0.553 0-1 0.448-1 1s0.447 1 1 1h24c0.553 0 1-0.448 1-1s-0.447-1-1-1z" />
    <path d="m40 38h-24c-0.553 0-1 0.448-1 1s0.447 1 1 1h24c0.553 0 1-0.448 1-1s-0.447-1-1-1z" />
  </svg>
))

export default ThreeBar
