export const FOOTER_LINKS = [
  {
    header: 'More Links',
    links: [
      { label: 'Know your soil', link: '/know-your-soil' },
      { label: 'Agri Input', link: '/agri-input' },
      { label: 'Agri Output', link: '/agri-output-Harvest-and-Market-Access' },
      { label: 'Health & Growth', link: '/health-growth-yield-prediction' },
      { label: 'Advisory', link: '/advisory-farmers-helpline-and-support' },
      {
        label: 'Farm Intelligence',
        link: '/farm-intelligence-AI-ML-Analytics',
      },
      {
        label: 'Agri Financing',
        link: '/Agricultural-Finance-Credit-Insurance',
      },
      { label: 'Solution for Farmers', link: '/solution-for-farmers' },
    ],
  },
  {
    header: 'Navigation',
    links: [
      { label: 'About us', link: '/company' },
      { label: 'Farmers', link: '/solution-for-farmers' },
      { label: 'Micro-Entrepreneur', link: '/solution-for-micro-entrepreneur' },
      {
        label: 'Institutional-Buyers',
        link: '/solution-for-institutional-buyers',
      },
      { label: 'DeHaat Network', link: '/business-model' },
      {
        label: 'Honest Farm',
        link: 'https://honestfarms.agrevolution.in/',
        external: true,
      },
      {
        label: 'Engineering',
        link: 'https://write.agrevolution.in/tagged/engineering',
        external: true,
      },
      { label: 'Farm Book', link: '/farmbook' },
      { label: 'Careers', link: '/careers' },
      { label: 'Contact us', link: '/contact-us' },
    ],
  },
]

const STRINGS = {
  COMMON: {
    DEHAAT: 'DeHaat',
    DIRECTLY_DOWNLOAD_APP: 'Directly Download Our App',
    DOWNLOAD: 'Download',
    DEHAAT_BUSINESS_APP: 'DeHaat-Business APP',
    DEHAAT_FARMER_APP: 'DeHaat-Farmer APP',
    CONNECT_WITH_US: 'Connect with us',
  },
  NAV: {
    ABOUT_US: 'About Us',
    AGRI_INPUT: 'Agri Input',
    AGRI_OUTPUT: 'Agri Output',
    CAREERS: 'Careers',
    COMPANY: 'Company',
    CONTACT_US: 'Contact Us',
    DEHAAT_NETWORK: 'DeHaat Network',
    ENGINEERING_BLOG: 'Engineering Blog',
    FARMBOOK: 'Farmbook',
    FINANCE: 'Finance',
    HEALTH_AND_GROWTH: 'Health & Growth',
    KNOW_YOUR_SOIL: 'Know Your Soil',
    SOLUTION_FOR_FARMER: 'Solution For Farmer',
    SOLUTION_FOR_INSTITUTIONAL_BUYERS: 'Solution For Institutional-Buyers',
    SOLUTION_FOR_MICRO_ENTREPRENEUR: 'Solution For Micro-Entrepreneur',
    SOLUTION: 'Solution',
    OUR_BRANDS: 'Our Brands',
    BLOGS: 'Blogs',
    HONEST_FARMS: 'Honest Farms',
    FARMPLUS: 'FarmPlus',
  },
  FOOTER: {
    ADVISORY: 'Advisory',
    AGRI_FINANCING: 'Agri Financing',
    AGRI_INPUT: 'Agri Input',
    AGRI_OUTPUT: 'Agri Output',
    AGRI_SOLUTIONS: 'Agri solutions',
    AI_ML_ANALYTICS: 'AI, ML & Analytics',
    BE_SOCIAL_WITH_US: 'Be Social With Us : ',
    CONTACT_US: 'Contact Us',
    COPYRIGHT_YEAR: '2022',
    COPYRIGHT: 'COPYRIGHT',
    CREDIT_AND_INSURANCE: 'Credit & Insurance',
    FARM_INTELLIGENCE: 'Farm Intelligence',
    HARVEST_AND_MARKET_ACCESS: 'Harvest & Market Access',
    HEALTH_AND_GROWTH: 'Health & Growth',
    HELPLINE_AND_SUPPORT: 'Helpline and Support',
    KNOW_YOUR_SOIL: 'Know Your Soil',
    PRIVACY_POLICY: 'Privacy Policy',
    SEED_NUTRITION_PROTECTION: 'Seed, Nutrition, Protection',
    SOIL_TESTING_AND_HEALTH_CARD: 'Soil Testing & Health Card',
    SOLUTION_FOR_FARMERS: 'Solution For Farmers',
    SOLUTION_FOR_INSTITUTIONAL_BUYERS: 'Solution For Institutional-Buyers',
    SOLUTION_FOR_MICRO_ENTREPRENEUR: 'Solution For Micro-Entrepreneur',
    TERMS_AND_CONDITIONS: 'Terms & Condition',
    YIELD_FORECAST: 'Yield Forecast',
  },
  LANDING_PAGE: {
    ADVISORY: 'Advisory',
    AGRI_INPUT: 'Agri Input',
    AGRI_OUTPUT: 'Agri Output',
    APPLY_NOW: 'Apply Now',
    BREAKING_RECORDS_REACHING_HEIGHTS:
      'Breaking records & reaching new heights',
    FARM_INTELLIGENCE: 'Farm Intelligence',
    FINANCE: 'Finance',
    GLOBAL_LEADERS: 'Global Leaders who support us',
    HEALTH_AND_GROWTH: 'Health & Growth',
    INVESTOR_AND_PARTNERS: 'Investor & Partners',
    KNOW_YOUR_SOIL: 'Know Your soil',
    LATEST_AGRITECH_NEWS: 'Keeping up on the latest agritech news',
    PHENOMENAL_OPPORTUNITIES: 'Phenomenal opportunities in Agri Sector',
    READ_BLOG: 'Read Blog',
    READ_NEWSLETTER: 'Read Newsletter',
    READ_THE_ARTICLE: 'Read the article',
    SEEDS_TO_MARKET: 'Seeds To Market',
    SLIDE_FIVE_ALT_TEXT: 'Hand holding a plat with glowing roots',
    SLIDE_FIVE_EYEBROW: '# In the news',
    SLIDE_FOUR_ALT_TEXT: 'We are hiring',
    SLIDE_FOUR_EYEBROW:
      '# Learned from the Past, Reshaping the Future, doing with a Stable & Secure Present',
    SLIDE_ONE_ALT_TEXT:
      'Farmer looking at his farm with mobile notification poping up on screen',
    SLIDE_ONE_EYEBROW: 'Building Technologies for the next Agri-Revolution',
    SLIDE_THREE_ALT_TEXT: 'Plant growing over coin filled glass jar',
    SLIDE_THREE_EYEBROW: '# Stronger together with',
    SLIDE_TWO_ALT_TEXT: 'Employees celebrating 2020 rewind',
    SLIDE_TWO_EYEBROW: '# Yearly Newsletter',
    WE_ARE_HIRING: 'We are Hiring',
    WHAT_WE_ARE_UPTO: 'What we are upto?',
    WHATS_IN_NEWS: "What's in news?",
    YEAR_REWIND: '2021 Year Rewind',
    LOGO: (companyName: string) => `${companyName} logo`,
  },
  MICRO_ENTREPRENEUR: {
    HEADING: 'Solution For Micro-Entrepreneur',
    SUB_HEADING: 'Agri Solution',
    DESCRIPTION_TEXT:
      'DeHaat provides tremendous scope for small entrepreneurship to thrive in rural area by making franchise operations possible at the grassroot level. Branded collectively as “DeHaat Center”, each franchise facility in the rural bazar is essentially a one-stop shop where the farmer can find direct access to all material inputs, technical advisory and market linkage for farm produce.',
    DESCRIPTION_TEXT_2:
      'The use of DeHaat Application to support the transformation of local micro-entrepreneurs which leads to make farming more socially, economically and environmentally sustainable is the key to serve more and more farmers. Keeping things simple and work flawlessly is what DeHaat App stands for.',
    DOWNLOAD: 'Download',
    TRAINING_BUSSINESS_DEVELOPMENT: 'Training and Business Development',
    SECTION_ONE_LABEL: 'Incentives & Inventory Planning',
    SECTION_ONE_DESP:
      'With the use of DeHaat App, supervision on non-capitalized assets, orders and stock become only a touch away.',
    SECTION_TWO_LABEL: 'Balance Sheet',
    SECTION_TWO_DESP:
      "You don't have to go manually and write everything down. All the financial statement will be in your hand through our App.",
    SECTION_THREE_LABEL: 'App-based fully-digitized operations',
    SECTION_THREE_DESP:
      'Just one click, and best quality input at very reasonable price will be waiting at your doorstep.',
    SECTION_FOUR_LABEL: 'Data',
    SECTION_FOUR_DESP:
      'Hassle free way to keep real time data with all the details of farmers in your pocket.',
    SECTION_FIVE_LABEL: 'Knowledge In Your Hand',
    SECTION_FIVE_DESP:
      'You can learn about different scientific methods of many crops which is pre-installed in our App.',
  },
  FARMER: {
    HEADING: 'Farmers Application-Solution For Farmers',
    SUB_HEADING: 'Agri Solution',
    DESCRIPTION_TEXT:
      'Among its multiple services, DeHaat App provides frequent crop reminder notification voice calls in local language to the farmer specific to their crop requirements. Thus, Farmers were informed about the right dosage of input as well as the best available crop consultation. It also provide the farmers with the flexibility to order input through our App and even sell their output directly to Dehaat through the app, removing middleman at mandi price. They even start getting customized advisory services through automated messages and voice calls based on real-time information.',
    DOWNLOAD: 'Download',
    FEATURES_FARMER_APP: 'Features Of Farmer Application',
    SECTION_ONE_LABEL: 'Helpline',
    SECTION_ONE_DESP:
      'Farmers can register their queries by uploading the issue on DeHaat App which can be solved in the least time possible.',
    SECTION_TWO_LABEL: 'Buy Input',
    SECTION_TWO_DESP:
      'You can order the best quality input through DeHaat App at a very reasonable price.',
    SECTION_THREE_LABEL: 'Sell Produce',
    SECTION_THREE_DESP:
      "They can also sell their produces directly to the institutional buyers at the end of the cropping season through Dehaat App at mandi price which ultimately improves the farmer's income.",
    SECTION_FOUR_LABEL: 'Soil Testing',
    SECTION_FOUR_DESP:
      'For the diagnosis of their soil, farmers request soil testing through DeHaat app which provides the baseline information of the health of the soil.',
    SECTION_FIVE_LABEL: 'Weather Report',
    SECTION_FIVE_DESP:
      'We keep on notifying about the upcoming weather changes from time to time through messages and proper App notification.',
  },
  KNOW_YOUR_SOIL: {
    PAGE_TITLE: 'Soil Testing & Soil Health Card | Toll Free No. 1800-103-6110',
    PAGE_DESCRIPTION:
      'Call on our toll-free number 1800-103-6110 now for Soil Testing, yield predictions, personalized advisory and many more. We help you in knowing your soil.',
    KNOW_YOUR_SOIL: 'Know Your Soil',
    SOIL_TESTING_AND_HEALTH_CARD: 'Soil Testing & Health Card',
    DESCRIPTION:
      'Knowing the Soil is where it all starts - the nutrient content and chemical composition of the soil defines how fertile the soil is and what level of plant life it can support and sustain on a consistent basis.\n\nWe help our farmers get the soil test done at our testing labs or bring in their existing soil health cards. Soil composition data points provide foundations for selecting the right kind of Agri Input (seed/fertilizer) and to generate advanced analytics e.g. yield predictions and Personalized Advisory.',
    SOIL_TEST_ALT: 'Request for soil test from DeHaat App',
    SOIL_TEST_STEP_DESCRIPTION:
      'Periodically we keep explaining the benefits of soil testing to farmers. Farmers request for soil collection through our DeHaat App or our Toll-free number which will be further sent to our lab for quality test.',
    SOIL_HEALTH_CARD_ALT: 'Health card can be accessed on DeHaat App',
    SOIL_HEALTH_CARD_STEP_DESCRIPTION:
      'We provide the soil health card in hard copy as well as on DeHaat App to farmers through which they will get the further information.',
    RECOMMENDATION_ALT:
      'Based on health card appropriate recommendation is provided',
    RECOMMENDATION_CARD_STEP_DESCRIPTION:
      'On the basis of soil health report, we recommend appropriate input with personalized advisory.',
    STEPS_INVOLVED_IN_SOIL_TESTING: 'Steps Involved In Soil Testing',
    HEALTH_CARD: 'Health Card',
    FARMER_PROFILE: 'Farmer Profile',
    HEALTH_CARD_FARMER_PROFILE:
      'Health card contains basic details of Farmers i.e. Name, Address, Land Details, Current crop details, Land Coordinates, etc.',
    SOIL_PROFILE: 'Soil Profile',
    HEALTH_CARD_SOIL_PROFILE:
      'All the soil sample results will be mentioned in it like PH value, O.C (Organic carbon), NPK (Nitrogen, Phosphorus, and Potassium).',
    RECOMMENDATIONS: 'Recommendations',
    HEALTH_CARD_RECOMMENDATIONS:
      'We will then suggest the best crop which will suit the soil and guide them with personalized advisory. The farmers will start receiving advisory on smart/feature phones.',
  },
  HEALTH_GROWTH: {
    PAGE_TITLE: 'DeHaat | Health & Growth',
    PAGE_DESCRIPTION:
      'DeHaat connects farmers to suppliers and buyers on a single platform. Online marketplace providing all the agricultural products and services to farmers.',
    HEALTH_AND_GROWTH: 'Health & Growth',
    YIELD_PREDICTION: 'Yield Prediction',
    DESCRIPTION:
      'Kitna Deti Hey? As applicable a question for a car as it could be for a crop. In fact, any farmer anywhere has been asking this question every cropping season. Traditionally the farmer has relied on previous experience to make a guesstimate - there has never been a reliable scientific way to make a reasonable prediction for obvious reason that the factors affecting the yield - weather, pests and harvest timing - have been difficult to predict.\n\nNot anymore. With advent of machine learning and related technologies, reliable yield prediction now seems very much possible and we have been investing our time and effort to build technology solutions that will help immensely our member farmers predicting their produce and bring Market Access to institutional buyers whose entire operations depend on the availability of quality produce on time.',
  },
  FARMER_HELPLINE: {
    SECTION_1_HEADING: 'Advisory',
    SECTION_1_SUB_HEADING: 'Farmers Helpline and Support',
    CONTACT: '1800 1036 110',
    SECTION_1_DESCRIPTION_TEXT_1:
      'What crop to grow? How best to grow it? What seed/fertilizer to use and when? Answers to these questions have never been more critical and relevant today with billions of mouths to feed while pursuing sustainable agriculture and dealing with climate change.',
    SECTION_1_DESCRIPTION_TEXT_2:
      'Traditionally crop advisory for farmers has come from other farmers and from local "historical experience". While that may be useful in limited ways, that mechanism does not incorporate proper scientific know-how and advances in agri technology and processes.',
    SECTION_2_HEADING:
      'We Provide Cost Effective Advisory Services To Our Associated Farmers',
    CROP_PLANNING_GROWTH: 'Crop Planning & Growth',
    DISEASE_DETECTION: 'Disease Detection',
    PESTICIDE_DOSAGE: 'Pesticide Dosage',
    BEST_HARVESTING_PRACTICES: 'Best Harvesting Practices',
    SECTION_2_DESCRIPTION_TEXT_3:
      'Our analytics technology seeks to provide personalized advisory based on each farmers individual farming conditions. DeHaat Technology Platform provides features to send out reminder notifications in local language relevant information related to crop monitoring, weather and market. Given that the Farmers still less digitally connected and culturally may prefer a human direct interaction - we provide advisory in offline modes as well. Just a phone call to our Call Center will provide the Farmer advisory from human operator. Farmer can also physically visit their nearest DeHaat Center. Our Technology Solution integrates all services on a single platform that includes last mile connection to the farmers doorstep including Agri Input he needs for next planting.',
    SECTION_3_HEADING: 'Automatic Crop Advisory Calls',
    SECTION_3_DESCRIPTION_TEXT:
      "You don't necessarily have to know details about every crop.DeHaat, through periodic crop advisory calls and messages, not only keeps you informed about all the major problems of ongoing/current crop but also guide you step-by-step to overcome the problem through detailed expert advice. The purpose of the crop advisory is to ensure right information and knowledge on crop production that is actionable is delivered to the farming household so that they are able to take action in time to achieve steady crop productivity and production.",
    SECTION_4_HEADING: 'DeHaat Advisory Team',
    SECTION_4_DESCRIPTION_TEXT:
      'With proper guidance by the advisory team of DeHaat , having knowledge of Agriculture with detail micro level on field experience of average 8+years which brings highly cost-effective module of farmers advisory services in local language, farmers can overcome the problems they face during whole process of cultivation.',
  },
}

export default STRINGS
