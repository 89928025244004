import Link from 'next/link'

interface Props {
  isActive: boolean
  url?: string
  title: string
  isDark: boolean
}

const NavLink = ({ isActive, url = '/', title, isDark }: Props) => {
  return (
    <li
      className={`${
        isDark ? 'text-white' : 'text-neutral-90'
      } text-base text-center font-natosans-medium ${
        isActive ? 'relative' : ''
      }`}
    >
      <Link href={url} className={isActive ? 'text-primary-110' : undefined}>
        {title}
      </Link>
      {isActive ? (
        <aside className="bg-primary-110 rounded-sm h-1 w-12 absolute -bottom-4 left-1/2 -translate-x-1/2" />
      ) : null}
    </li>
  )
}

export default NavLink
