import Link from 'next/link'
import { useRouter } from 'next/router'

import { desktopNavLinks } from '@/constants/navLinks'

import HoverDropdown, { DropDownTitleWithCaret } from '../hoverDropdown'
import DeHaatLogoFooter from '../svg/DeHaatLogoFoooter'
import DesktopDropdownLink from './desktopDropdownLink'
import NavLink from './navLink'

interface Props {
  theme: 'dark' | 'normal'
}

const DesktopHeader = ({ theme }: Props) => {
  const isDark = theme === 'dark'
  const { pathname } = useRouter()

  return (
    <nav className="hidden lg:flex py-[18px] px-6 justify-center w-full">
      <section className="flex justify-between w-full items-center">
        <Link href="/">
          <DeHaatLogoFooter className="fill-primary-100 h-14 w-[155px] lg:h-[64px] lg:w-[176.77px]" />
        </Link>
        <ul className="ml-4 flex space-x-4 lg:space-x-8 items-center">
          {desktopNavLinks.map(({ title, url, children, external }) =>
            Array.isArray(children) && children.length > 0 ? (
              <li key={title}>
                <HoverDropdown
                  title={
                    <DropDownTitleWithCaret
                      title={title}
                      titleClassNames={`${
                        isDark ? 'text-white' : 'text-neutral-90'
                      } text-base font-natosans-medium`}
                      iconClassNames={`fill-transparent stroke-[1.6] ${
                        isDark ? 'stroke-white' : 'stroke-neutral-90'
                      }`}
                    />
                  }
                  dropdownContainerClassNames="bg-white"
                  dropdownItemClassNames="hover:bg-slate-300"
                  itemList={children.map((child) => ({
                    key: child.url,
                    item: (
                      <DesktopDropdownLink
                        url={child.url}
                        title={child.title}
                        isExternal={child.external}
                        isActive={pathname === child.url}
                      />
                    ),
                  }))}
                />
              </li>
            ) : (
              <NavLink
                key={url}
                url={url}
                isActive={pathname === url}
                title={title}
                isDark={isDark}
              />
            )
          )}
        </ul>
      </section>
    </nav>
  )
}

export default DesktopHeader
