import Link from 'next/link'
import { useCallback, useState } from 'react'

import ClickDropdown from '@/components/clickDropdown'
import CrossInCircle from '@/components/svg/CrossInCircle'
import DeHaatLogo from '@/components/svg/DehaatLogo'
import ThreeBar from '@/components/svg/ThreeBar'
import { mobileNavLinks } from '@/constants/navLinks'
import styles from '@/styles/MobileHeader.module.css'

interface Props {
  theme: string
}

const MobileHeader = ({ theme }: Props) => {
  const [menuExpanded, setMenuExpanded] = useState<boolean | null>(null)

  const onMenuToggle = useCallback(() => {
    setMenuExpanded((prev) => !prev)
  }, [])

  const navStyles = menuExpanded
    ? `${styles.expand} visible`
    : `${styles.collapse} invisible`

  return (
    <>
      <section className="flex flex-row w-full justify-between items-center px-6 mt-4 lg:hidden">
        <Link href="/" className="z-30" aria-label="Navigate to home page">
          <DeHaatLogo
            className={theme === 'dark' ? 'fill-white' : 'fill-dehaat-green'}
          />
        </Link>
        <button
          onClick={onMenuToggle}
          aria-label="Trigger mobile navigation"
          aria-expanded={!!menuExpanded}
          className="z-10"
        >
          <ThreeBar
            width="30"
            height="30"
            className={theme === 'dark' ? 'fill-white' : 'fill-black'}
          />
        </button>
      </section>
      <nav
        className={`fixed z-20 top-0 left-0 bg-black/[.96] h-screen w-screen ${styles.mobileNav} ${navStyles} lg:hidden`}
        aria-label="Mobile navigation"
      >
        <section
          className={`px-8 mt-4 ${styles.mobileNavHeader} flex items-center justify-end`}
        >
          <button
            onClick={onMenuToggle}
            aria-label="Close mobile navigation"
            aria-expanded={!!menuExpanded}
          >
            <CrossInCircle fill="#fff" width="30" height="30" />
          </button>
        </section>
        <ul className="px-8 py-0 m-0 text-white list-none list-outside h-full overflow-y-auto">
          {mobileNavLinks.map(({ title, children, url, external }) => (
            <li
              className="border-b border-white border-solid font-natosans"
              key={title}
            >
              {children ? (
                <ClickDropdown title={title} buttonClassNames="py-2 block">
                  <ul className="p-0 py-2 m-0 text-white list-none list-outside">
                    {children.map(({ title: itemTitle, url: itemURL }) => (
                      <li key={itemTitle}>
                        <a
                          href={itemURL}
                          className="no-underline text-lg block py-0.5"
                        >
                          {itemTitle}
                        </a>
                      </li>
                    ))}
                  </ul>
                </ClickDropdown>
              ) : (
                <a href={url} className="no-underline text-lg py-2 block">
                  {title}
                </a>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default MobileHeader
