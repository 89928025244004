import { ReactNode, useEffect, useRef, useState } from 'react'

import Caret from '../svg/Caret'

interface Props {
  title: string
  children: ReactNode
  buttonClassNames?: string
}

const ClickDropdown = ({ title, children, buttonClassNames = '' }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const childRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (childRef.current) {
      if (isExpanded) {
        childRef.current.setAttribute(
          'style',
          `height: ${childRef.current.scrollHeight}px;`
        )
      } else {
        childRef.current.setAttribute('style', `height: 0;`)
      }
    }
  }, [isExpanded])

  return (
    <>
      <button
        className={`flex items-center w-full justify-between text-lg ${buttonClassNames}`}
        aria-expanded={isExpanded}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <span>{title}</span>
        <div
          className={`${
            isExpanded ? 'rotate-0' : 'rotate-180'
          } transition-all duration-300`}
        >
          <Caret width="18" height="18" fill="#fff" />
        </div>
      </button>
      <div
        className={`transition-all duration-300 overflow-hidden`}
        ref={childRef}
      >
        {children}
      </div>
    </>
  )
}

export default ClickDropdown
