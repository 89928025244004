import { forwardRef, ReactNode, Ref } from 'react'

import CaretDown from '../svg/CaretDown'

interface Props {
  title: ReactNode
  itemList: { key: string; item: ReactNode }[]
  dropdownContainerClassNames?: string
  dropdownItemClassNames?: string
  containerClassNames?: string
}

const HoverDropdown = forwardRef(
  (
    {
      title,
      itemList,
      dropdownContainerClassNames = '',
      dropdownItemClassNames = '',
      containerClassNames = '',
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <div
        className={`trainsition-all duration-300 flex items-center relative group ${containerClassNames}`}
        ref={ref}
      >
        {title}
        <ul
          className={`transition-all duration-300 absolute z-10 top-full left-0 py-2 shadow opacity-0 invisible group-hover:opacity-100 group-hover:visible ${dropdownContainerClassNames}`}
        >
          {itemList.map(({ key, item }) => (
            <li
              className={`trainsition-all duration-300 px-2 whitespace-nowrap ${dropdownItemClassNames}`}
              key={key}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  }
)

interface DropDownTitleWithCaretProps {
  title: string
  titleClassNames?: string
  iconClassNames?: string
  caretHeight?: number
  caretWidth?: number
}

/**
 * A component which provides a title implementation with caret icon for dropdown menu
 *
 * Note: If used seperately for the icon rotation to work properly assign
 * `group` classname to the parent element of the component.
 */
export const DropDownTitleWithCaret = ({
  title,
  titleClassNames = '',
  iconClassNames = '',
  caretHeight = 18,
  caretWidth = 18,
}: DropDownTitleWithCaretProps) => (
  <>
    <span className={`inline-block mr-2 ${titleClassNames}`}>{title}</span>
    <span className="inline-block">
      <CaretDown
        height={caretHeight}
        width={caretWidth}
        className={`transition-all duration-300 group-hover:rotate-180 ${iconClassNames}`}
      />
    </span>
  </>
)

export default HoverDropdown
