import Link from 'next/link'

interface Props {
  isActive: boolean
  url: string
  title: string
  isExternal?: boolean
}

const DesktopDropdownLink = ({ isActive, url, title, isExternal }: Props) => {
  return (
    <Link
      href={url}
      className={`block px-2 py-2 text-base font-natosans relative ${
        isActive ? 'text-primary-110' : 'text-neutral-90'
      }`}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noreferrer' : undefined}
    >
      {title}
      {isActive ? (
        <aside className="absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-1 bg-primary-110 rounded-sm" />
      ) : null}
    </Link>
  )
}

export default DesktopDropdownLink
