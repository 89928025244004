import DesktopHeader from './desktop'
import MobileHeader from './mobile'

interface Props {
  theme?: 'dark' | 'normal'
  className?: string
}

const Header = ({ theme = 'normal', className }: Props) => {
  return (
    <header className={className}>
      <MobileHeader theme={theme} />
      <DesktopHeader theme={theme} />
    </header>
  )
}

export default Header
