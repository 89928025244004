import { forwardRef, Ref, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGSVGElement>

const CrossInCircle = forwardRef((props: Props, ref: Ref<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" ref={ref} {...props}>
    <path d="m256 512c-141.16 0-256-114.84-256-256s114.84-256 256-256 256 114.84 256 256-114.84 256-256 256zm0-480c-123.52 0-224 100.48-224 224s100.48 224 224 224 224-100.48 224-224-100.48-224-224-224z" />
    <path d="m176.81 351.19c-4.0977 0-8.1953-1.5547-11.309-4.6914-6.25-6.25-6.25-16.383 0-22.633l158.4-158.4c6.2539-6.25 16.387-6.25 22.637 0s6.25 16.383 0 22.637l-158.4 158.4c-3.1562 3.1367-7.25 4.6914-11.324 4.6914z" />
    <path d="m335.19 351.19c-4.0938 0-8.1914-1.5547-11.305-4.6914l-158.4-158.38c-6.2539-6.25-6.2539-16.383 0-22.633 6.25-6.2539 16.383-6.2539 22.633 0l158.4 158.4c6.2539 6.25 6.2539 16.383 0 22.633-3.1328 3.1172-7.2305 4.6719-11.328 4.6719z" />
  </svg>
))

export default CrossInCircle
